<template>
  <v-main
    class="fill-height ml-4 mr-4 mt-4 mb-4"
    fluid
    style="margin: auto !important; padding: 0px; width: 90%"

  >
<v-row>
 
    <v-col>
         <v-card
    class="mx-auto"
    outlined
    fill-height
   >

    <v-list-item>
      <v-list-item-content>
   <div class="text-center mt-4" v-if="!sitesLoaded">
        <v-progress-circular
      align-center
      indeterminate
      color="primary"
      :size="70"
      :width="7"
    ></v-progress-circular>
      </div>
      <v-simple-table v-if="sitesLoaded">
    <template v-slot:default>
      <thead>
        <tr>
          <th class="text-left">
            Uri
          </th>
          <th class="text-left">
            Location
          </th>
          <th class="text-left">
            PHP Version
          </th>
           <th class="text-left">
            Environment
          </th>
             <th class="text-left">
            Site Status
          </th>
          <th class="text-left">
            Duplicate
          </th>
        </tr>
      </thead>
      <tbody>
       
        <tr
          v-for="site in sites"
          :key="site.uri"
        >
          <td> <a :href="'/sites/' + site.uri"> {{ site.uri }} </a> </td>
          <td>  {{ site.country }} ({{ site.city }})  </td>
          <td>  {{ site.php_version }} </td>
          <td>  {{ site.environment }} </td>
          <td>  {{ site.status }} </td>
          <td>  <v-btn @click="duplicateDialog = true">Duplicate</v-btn> </td>

        </tr>
    
      </tbody>
    </template>
  </v-simple-table>
      </v-list-item-content>
    
    </v-list-item>

        <div class="text-right" v-if="sitesLoaded">
            
         <v-btn
            class="mx-2 mb-2"
            fab
            dark
            color="indigo"
            align="right"
            @click="$router.push('/sites/new')"
            >
            <v-icon dark>
                mdi-plus
            </v-icon>
            </v-btn>

            </div>

      </v-card>
    </v-col>
</v-row>

  <v-dialog
      v-model="duplicateDialog"
      persistent
      max-width="600px"
    >
 
      <v-card>
        <v-card-title>
          <span class="headline">Duplicate site</span>
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="deployStagingDialog = false"
          >
            Close
          </v-btn>
          <v-btn
            text
            @click="deployStaging(domain,restoreBackupId)"
          >
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</v-main>
</template>

<script>
import jwt from 'jsonwebtoken'

export default {
  data: () => ({
    // name: localStorage.getItem('name'),
    sites: [],
    sitesLoaded: false,
    duplicateDialog: false,
  }),
  created () {
       
       var token = jwt.decode(this.$store.state.token)
       this.account_type = token.account_type
       
       this.$store.dispatch('getSites')
        .then( response => {
          console.log(response.data)
          this.sites = response.data
          this.sitesLoaded= true
        })
        .catch(err => {
             console.log(err)
        })
  }

}
</script>
